module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hlib Lozhkovyi – Senior Javascript Software Engineer","short_name":"Hlib Lozhkovyi","description":"Hlib's Lozhkovyi personal website. Senior react/node/typescript engineer.","lang":"en","display":"standalone","icon":"src/images/fav.png","start_url":"/","background_color":"#663399","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf7867224947951f2e1def2aeb16f76f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
